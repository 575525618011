import React from "react";
import RequestButton from "../RequestButton";

const ADHDInfo = () => {
  return (
    <div className="w-full flex flex-col justify-center items-center bg-white">
      <div className="w-4/5 lg:w-1/2 flex flex-col justify-start items-start py-12">
        <p className="text-3xl font-thin text-gray-800 mb-6">ADHD Q & A</p>

        <p className="text-xl text-gray-800 mb-2">What is ADHD?</p>
        <p className="text-md text-gray-600 mb-12">
          ADHD, or attention-deficit/hyperactivity disorder, is a disease that
          often appears during childhood, but it affects adults too. Common
          characteristics of the disorder include hyperactivity, inattention,
          and impulsive behavior. AHDH can negatively affect work performance
          and relationships with other people. Treatment often improves your
          quality of life.
        </p>

        <p className="text-xl text-gray-800 mb-2">
          What are the symptoms of ADHD?
        </p>
        <p className="text-md text-gray-600 mb-2">
          Common signs and symptoms of ADHD are:
        </p>
        <ul className="text-gray-600 text-md list-inside list-disc pl-4 mb-2">
          <li>Difficulty paying attention or focusing</li>
          <li>Impulsivity</li>
          <li>Appearing not to listen</li>
          <li>Difficulty following through with tasks</li>
          <li>Problems following instructions</li>
          <li>Disorganization</li>
          <li>Losing items or getting distracted easily</li>
          <li>Constant fidgeting or moving around</li>
          <li>Difficulty sitting still</li>
          <li>Interrupting conversations</li>
          <li>Problems with relationships</li>
        </ul>
        <p className="text-md text-gray-600 mb-12">
          ADHD might make completing everyday tasks and performing well at work
          difficult. Simple treatments help you achieve success and thrive in
          life.
        </p>

        <p className="text-xl text-gray-800 mb-2">
          What are the risk factors for ADHD?
        </p>
        <p className="text-md text-gray-600 mb-12">
          The cause of ADHD isn’t known, but certain factors might increase your
          risk of having it. Examples include family history of ADHD, maternal
          drug or alcohol use, smoking during pregnancy, premature birth, and
          environmental toxin exposure.
        </p>

        <p className="text-xl text-gray-800 mb-2">How is ADHD diagnosed?</p>
        <p className="text-md text-gray-600 mb-12">
          While there is no specific test for ADHD, your primary care doctor can
          diagnose it after asking questions about your behavioral
          characteristics. If ADHD signs and symptoms negatively affect you or a
          family member’s quality of life, the Sunstate Medical Associates team
          offers effective, evidence-based treatments.
        </p>

        <p className="text-xl text-gray-800 mb-2">How is ADHD treated?</p>
        <p className="text-md text-gray-600 mb-3">
          To diminish symptoms of ADHD and help you thrive in any environment,
          your doctor might recommend:
        </p>
        <p className="text-lg text-gray-700 mb-2">Medications</p>
        <p className="text-md text-gray-600 mb-3">
          Medications designed for ADHD help you focus and reduce hyperactivity
          or impulsive behavior. Your provider discusses the pros and cons of
          ADHD medication to make sure it’s the right for you.
        </p>
        <p className="text-lg text-gray-700 mb-2">Behavioral therapy</p>
        <p className="text-md text-gray-600 mb-3">
          ADHD behavioral therapy for adults might include an array of
          counseling sessions. During behavioral therapy sessions, you learn
          behavior-changing strategies, such as setting regular daily routines
          and other healthy lifestyle habits.
        </p>
        <p className="text-md text-gray-600 mb-10">
          Don’t let ADHD negatively affect you or a loved one when simple
          treatments are available at Sunstate Medical Associates. Call the
          office to speak with a friendly team member or book online to find out
          which solution is right for you.
        </p>

        <RequestButton />
      </div>
    </div>
  );
};

export default ADHDInfo;
