import React from "react";
import Layout from "../../components/Layout";
import PageHeader from "../../components/PageHeader";
import Conditions from "../../components/Conditions";
import ServicesHeader from "../../components/ServicesHeader";
import ADHDInfo from "../../components/services/ADHDInfo";

const ADHD = () => {
  return (
    <Layout>
      <PageHeader text="ADHD" />
      <ServicesHeader>
        If you experience inattention, impulsivity, and hyperactivity,
        attention-deficit/hyperactivity disorder (ADHD) might be to blame. At
        Sunstate Medical Associates in Lake Mary, Florida, the experienced
        internal medicine physicians diagnose and treat ADHD to improve focus
        and your quality of life. Call the office and speak with a friendly team
        member today or book online.
      </ServicesHeader>
      <ADHDInfo />
      <Conditions />
    </Layout>
  );
};

export default ADHD;
